.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

:root {
    --toastify-color-error: #f5222d;
    --toastify-text-color-error: #fff;
    --toastify-color-success: #595959;
    --toastify-text-color-success: #fff;
    --toastify-close-color-success: #8c9196;
    --toastify-toast-width: max-content;
    --toastify-toast-min-height: unset;
}

#root {
    display: flex;
    justify-content: center;
}

.Toastify__toast {
    width: 343px;
    padding: 12px 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-weight: 500;
}

.Toastify__toast-icon {
    display: none;
}

.Toastify__toast-body {
    padding: 0;
    margin: 0;
}

.Toastify__close-button {
    display: flex;
    align-items: center;
    align-self: center;
}

.Toastify__toast--error {
    background-color: var(--toastify-color-error);
    color: var(--toastify-text-color-error);
}

.Toastify__toast--error .Toastify__close-button {
    color: var(--toastify-text-color-error);
    opacity: 1;
}

.Toastify__toast-container--top-center {
    top: 45px;
}

.Toastify__toast-container--top-right {
    top: 80px;
    right: 24px;
}

.Toastify__toast--success {
    background-color: var(--toastify-color-success);
    color: var(--toastify-text-color-success);
}

.Toastify__toast--success .Toastify__close-button {
    color: var(--toastify-close-color-success);
    opacity: 1;
}

/* SP Version */
@media screen and (max-width: 480px) {
    .Toastify__toast-container {
        display: flex;
        justify-content: center;
    }
}